<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("services.services.order-status") }}
        </div>
        <template v-slot:right>
          <button
            @click="
              submitFormState = true;
              initSubmit();
            "
            v-if="selectedResourceServices && selectedResourceServices.length"
          >
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section" v-if="serviceOrder">
      <li class="clebex-item-section-item full-right-underline">
        <div
          class="clebex-item-content-wrapper"
          @click="showInfoBox = !showInfoBox"
          style="cursor: pointer;"
        >
          <div
            class="clebex-section-input"
            @click="showInfoBox = !showInfoBox"
            style="cursor: pointer;"
          >
            <label
              class="clebex-section-input-label"
              for="orderNumber"
              style="cursor: pointer;"
              @click="showInfoBox = !showInfoBox"
              >{{ displayLabelName("services.services.order-number") }}</label
            >
            <span
              style="cursor: pointer; color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
              @click="showInfoBox = !showInfoBox"
            >
              {{ serviceOrder.data.order_id }}</span
            >
          </div>
          <span class="follow-up-icon-item" style="cursor: pointer;">
            <icon
              icon="#cx-app1-arrow-right-12x12"
              width="12"
              height="12"
              style="cursor: pointer; margin-right: 15px; margin-top: 5px;"
              :style="[
                !showInfoBox
                  ? 'transform: rotate(90deg)'
                  : 'transform: rotate(-90deg)'
              ]"
            />
          </span>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="resource">{{
              displayLabelName("services.services.resource")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ serviceOrder.data.path }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="address">{{
              displayLabelName("services.services.address")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ serviceOrder.data.address }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="requestBy">{{
              displayLabelName("services.services.request-by")
            }}</label>
            <Field
              name="name"
              as="input"
              type="text"
              id="requestBy"
              disabled="true"
              v-model="serviceOrder.data.request_by"
            />
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="dateTime">{{
              displayLabelName("services.services.created-at")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ serviceOrder.data.created_at }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="dateTime">{{
              displayLabelName("services.services.date-time")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ serviceOrder.data.delivery_date }}
              {{ serviceOrder.data.delivery_time }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="dateTime">{{
              displayLabelName("services.service-items.service-items")
            }}</label>
            <Field
              name="name"
              as="input"
              type="text"
              id="dateTime"
              disabled="true"
              v-model="serviceOrder.data.service_items.length"
            />
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox && serviceOrder.data.deleted_at"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="address">{{
              displayLabelName("services.services.deleted-at")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ serviceOrder.data.deleted_at }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        style="cursor: pointer;"
        v-if="showInfoBox"
      >
        <router-link
          :to="{
            name: 'r_services-update-order-status-history'
          }"
          class="clebex-item-content-wrapper"
        >
          <div class="clebex-section-input" style="cursor: pointer;">
            <label class="clebex-section-input-label" style="cursor: pointer;">
              {{ displayLabelName("services.services.history") }}
            </label>
          </div>
          <span class="follow-up-icons" style="cursor: pointer;">
            <span class="follow-up-icon-item" style="cursor: pointer;">
              <icon
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
                style="cursor: pointer;"
              />
            </span>
          </span>
        </router-link>
      </li>
    </ul>

    <div v-if="serviceOrder" class="order-services">
      <div
        class="order-service"
        style="font-weight: bold; background-color: #e9f8f7;"
      >
        <div style="width: 90%;">
          {{ displayLabelName("services.services.service-item") }}
        </div>
        <div style="width: 13%;">
          {{ displayLabelName("services.services.quantity-short") }}
        </div>
      </div>
      <div
        class="order-service"
        v-for="item in serviceOrder.data.service_items"
        :key="item.id"
      >
        <div style="flex: 1;" @click="updateItemStatus(item.id)">
          <span>{{ item.name }}</span
          ><br />
          <span class="item-status">{{ item.service_status_name }}</span>
        </div>
        <div
          style="width: 10%; display: flex; align-items: center;"
          @click="updateItemStatus(item.id)"
        >
          {{ item.quantity }}
        </div>
        <div
          style="width: 3%; display: flex; align-items: center; font-weight: bold; font-size: 20px; color: #afaeae;"
          @click="updateItemStatus(item.id)"
        >
          <icon
            style="height: 30px; transform: rotate(180deg);"
            icon="#cx-hea1-arrow-left"
          />
        </div>
      </div>
    </div>

    <!-- STATUSES - START -->
    <ul
      class="clebex-item-section pill"
      v-if="
        listOfServiceStatuses && listOfServiceStatuses.length && showStatuses()
      "
    >
      <li
        class="clebex-item-section-item"
        v-for="serviceStatus in listOfServiceStatuses"
        :key="serviceStatus.id"
      >
        <button
          v-if="showStatus(serviceStatus.type) && !checked(serviceStatus.type)"
          class="clebex-item-content-wrapper"
          @click="statusUpdate(serviceStatus.type)"
          :style="[
            !disableStatus(serviceStatus.type) && !checked(serviceStatus.type)
              ? 'background-color: #24b7af; color: white !important;'
              : ''
          ]"
        >
          <span class="label">
            <span
              class="highlight"
              :style="[
                disableStatus(serviceStatus.type) &&
                !checked(serviceStatus.type)
                  ? 'color: lightgray;'
                  : '',
                !disableStatus(serviceStatus.type) &&
                !checked(serviceStatus.type)
                  ? 'color: white;'
                  : ''
              ]"
              >{{ serviceStatus.name }}</span
            >
          </span>
          <span class="follow-up-icons">
            <span
              class="follow-up-icon-item"
              v-if="checked(serviceStatus.type)"
            >
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
    <!-- STATUSES - END -->
    <button type="submit" style="display: none;" ref="submitFormBtn">
      Submit form
    </button>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";

export default {
  name: "UpdateStatus",
  data() {
    return {
      searchQuery: "",
      submitFormState: false,
      showInfoBox: false
    };
  },
  created() {
    this.getServiceOrder(this.$route.params.service_order_id);
    this.getServiceStatuses(this.serviceResource);
  },
  watch: {
    routeParam() {
      if (this.routeParam) {
        this.getServiceOrder(this.$route.params.service_order_id);
        this.getServiceStatuses(this.serviceResource);
      }
    }
  },
  computed: {
    ...mapState("service", ["serviceStatuses", "serviceOrder"]),
    routeParam() {
      return this.$route.params.service_order_id;
    },
    orderStatus() {
      if (
        this.serviceOrder &&
        this.serviceOrder.data &&
        this.serviceOrder.data.service_items
      ) {
        return this.serviceOrder.data.service_items[0].service_status;
      }
      return "";
    },
    listOfServiceStatuses() {
      if (
        this.serviceStatuses &&
        this.serviceStatuses.data &&
        this.serviceStatuses.data.length
      ) {
        return this.serviceStatuses.data;
      }

      return null;
    },
    serviceItem() {
      if (
        this.serviceOrder &&
        this.serviceOrder.data &&
        this.serviceOrder.data.service_items
      ) {
        return this.serviceOrder.data.service_items.find(
          el => el.id == this.$route.params.service_item_id
        );
      }

      return null;
    }
  },
  methods: {
    ...mapActions("service", [
      "setServiceStatus",
      "getServiceStatuses",
      "updateServiceStatus",
      "getServiceOrder",
      "updateServiceStatus"
    ]),
    checkIfStatusesAreSame() {
      if (this.serviceOrder && this.serviceOrder.data) {
        if (
          this.serviceOrder.data.service_items &&
          this.serviceOrder.data.service_items.length
        ) {
          let serviceStatus = this.serviceOrder.data.service_items[0]
            .service_status;

          for (
            var i = 0;
            i < this.serviceOrder.data.service_items.length;
            i++
          ) {
            if (
              this.serviceOrder.data.service_items[i].service_status !=
              serviceStatus
            ) {
              return false;
            }
          }

          return true;
        }
      }

      return false;
    },
    showStatuses() {
      if (this.serviceOrder && this.serviceOrder.data) {
        if (this.serviceOrder.data.service_items.length == 1) {
          return true;
        } else {
          return this.checkIfStatusesAreSame();
        }
      }
      return false;
    },
    updateItemStatus(itemId) {
      if (!this.serviceOrder.data.deleted_at) {
        this.$router.push({
          name: "r_services-update-item-status",
          params: {
            service_item_id: itemId
          }
        });
      }
    },
    checked(name) {
      return this.orderStatus == name;
    },
    showStatus(currentStatus) {
      if (this.orderStatus == "ACKNOWLEDGED") {
        if (Array("ORDER").includes(currentStatus)) {
          return false;
        }
      }
      if (this.orderStatus == "PREPARATION") {
        if (Array("ORDER", "ACKNOWLEDGED").includes(currentStatus)) {
          return false;
        }
      }
      if (this.orderStatus == "DELIVERED") {
        if (
          Array("ORDER", "ACKNOWLEDGED", "PREPARATION").includes(currentStatus)
        ) {
          return false;
        }
      }
      if (this.orderStatus == "RECEIVED") {
        if (
          Array("ORDER", "ACKNOWLEDGED", "PREPARATION", "DELIVERED").includes(
            currentStatus
          )
        ) {
          return false;
        }
      }
      return true;
    },
    disableStatus(currentStatus) {
      if (this.orderStatus == "ORDER") {
        if (Array("ACKNOWLEDGED").includes(currentStatus)) {
          return false;
        }
      }
      if (this.orderStatus == "ACKNOWLEDGED") {
        if (Array("PREPARATION").includes(currentStatus)) {
          return false;
        }
      }
      if (this.orderStatus == "PREPARATION") {
        if (Array("DELIVERED").includes(currentStatus)) {
          return false;
        }
      }
      if (this.orderStatus == "DELIVERED") {
        if (Array("RECEIVED").includes(currentStatus)) {
          return false;
        }
      }
      return true;
    },
    statusUpdate(status) {
      if (!this.serviceOrder.data.deleted_at) {
        if (!this.disableStatus(status)) {
          for (
            var i = 0;
            i < this.serviceOrder.data.service_items.length;
            i++
          ) {
            this.updateServiceStatus({
              order: this.serviceOrder.data.order_id,
              item: this.serviceOrder.data.service_items[i].id,
              status: status
            });
          }

          this.$router.push({
            name: "r_services"
          });
        }
      }
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
<style>
.order-services {
  display: flex;
  flex-direction: column;
  width: 91%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  background-color: white;
  margin-top: 27px;
  overflow: hidden;
}
.order-service {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-top: 1px solid lightgray;
  cursor: pointer;
}
.order-service:first-child {
  border-top: none;
}
.item-status {
  font-size: 14px;
  font-weight: bold;
  color: darkgreen;
}
</style>
